<template>
  <div class="layers" :style="screenStyle">
    <div
      class="panel"
      :style="{ paddingTop: $store.state.layer.headerHeight + 'px' }"
    >
      <Panel />
    </div>
  </div>
</template>

<script>

import Panel from "./panel";

export default {
  name: "layer",
  // inject: ["global"],
  components: {
   
    Panel,
   
  },
  data() {
    return {
      
    };
  },
 
  mounted() {
   
  },
  computed: {
    visible() {
      return this.$store.state.layer.popupShow;
    },
    offset() {
      return this.$store.state.screen.offset;
    },
    screenStyle() {
      const { width, height, fontSize } = this.$store.state.screen;
      const { hueRotate } = this.$store.state.layer;
      return {
        width: width + "px",
        height: height - this.offset + "px",
        fontSize: fontSize + "px",
        filter: `hue-rotate(${hueRotate}deg)`,
      };
    },
    bodyStyle() {
      const { height, sceneHeight } = this.$store.state.screen;
      return {
        height: height == sceneHeight ? "100%" : sceneHeight + "px",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.layers {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  z-index: 9999;
  pointer-events: none;
  .header-layers {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    pointer-events: auto;
    z-index: 999;
  }
  .panel {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  .middle-layers {
    pointer-events: none;
    display: flex;
    height: 100%;
    overflow: hidden;
    width: 100%;
    // justify-content: space-between;
    .center {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .body {
        width: 100%;
        .map {
          pointer-events: auto;
          position: relative;
        }
      }
      .foot {
        // background: rgba(0, 0, 0, 0.521);
        // margin-bottom: 10px;
        pointer-events: auto;
      }
    }
    .left,
    .right {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      height: 100%;
      margin-top: 10px;
      // position: relative;
      // &::before {
      //   content: "";
      //   position: absolute;
      //   width: 100px;
      //   height: 100%;
      //   background: linear-gradient(
      //     to right,
      //     #000 1%,
      //     transparent 90%,
      //     transparent
      //   );
      //   display: block;
      //   right: -50px;
      //   top: 0;
      // }
    }
    // .right {
    //   &::before {
    //     background: linear-gradient(
    //       to left,
    //       #000 1%,
    //       transparent 90%,
    //       transparent
    //     );
    //     left: -50px;
    //   }
    // }
    .chart1,
    .chart2,
    .chart3,
    .chart4,
    .chart5,
    .chart6,
    .chart7 {
      min-height: 150px !important;
      margin-bottom: 18px;
      width: 100%;
      .tit {
        background: url("../../assets/img/skin/blackgold/title_bg.png")
          no-repeat;
        background-size: 100% 60%;
        margin-bottom: 15px;
        font-size: 16px /* 13/80 */;
        position: relative;
        padding-left: 25px;
        height: 20px;
        font-weight: 600;
        color: #ddd;
        display: flex;
        // &::before {
        //   content: "";
        //   width: 0.15rem;
        //   height: 0.15rem;
        //   background: #ab9065;
        //   position: absolute;
        //   top: 20%;
        //   left: 0;
        // }
        &.icon {
          background: none;
          // padding-left: 0.4rem;
          // &::before {
          //   content: "";
          //   width: 0.3rem;
          //   height: 0.3rem;
          //   background: url("../assets/img/icon.png") no-repeat;
          //   background-size: 90%;
          //   position: absolute;
          //   top: -10%;
          //   left: 0;
          // }
        }
        .btn {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: relative;
          top: -1px;
          span {
            cursor: pointer;
            display: inline-block;
            position: absolute;
            left: 6px;
            top: 0px;
            &:hover {
              &::after {
                border-bottom-color: #ffb835;
              }
            }
            &::after {
              content: "";
              border: 6px solid transparent;
              border-bottom: 6px solid rgba(255, 255, 255, 0.7);
              position: absolute;
              top: -4px;
              left: 0;
            }
            &:nth-child(2) {
              top: 10px;
              left: 6px;
              &::after {
                content: "";
                border: 6px solid transparent;
                border-top: 6px solid rgba(255, 255, 255, 0.7);
                position: absolute;
                top: 2px;
                left: 0;
              }
              &:hover {
                &::after {
                  border-top-color: #ffb835;
                }
              }
            }
          }
        }
      }
      .context {
        display: flex;
        align-items: center;
        .info {
          transform: scale(1);
          div {
            background: url("../../assets/img/s-box.png") no-repeat;
            background-size: 100% 100%;
            padding: 10px 15px;
            margin: 10px 0;
          }
        }
      }
    }
    .chart1,
    .chart2,
    .chart5,
    .chart6 {
      background: url("../../assets/img/skin/blackgold/title2_bg.png") no-repeat;
      background-size: 100% 100%;

      .tit {
        padding: 5px 0 0 10px;
        background: none;
      }
      // position: relative;
      // right: 0.5rem;
    }
    .chart5 {
      .tit {
        padding-top: 10px;
      }
      .context {
        height: 190px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        img {
          position: absolute;
          bottom: 15px;
          left: 20px;
          width: 140px;
        }
      }
    }
    .chart6 {
      .tit {
        padding-top: 8px;
      }
      .context {
        height: 170px;
      }
    }
    .chart5,
    .chart6 {
      .info {
        position: relative;
        top: -10px;
      }
    }
  }
  .bottom-layers {
    width: 100%;
    pointer-events: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
