var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"css2d"},[_vm._l((_vm.lineList),function(item,index){return _c('div',{key:item.label + '_' + index,ref:("css2DFactory" + index),refInFor:true,class:[
      'factory',
      'factoryText',
      _vm.$store.state.model.currentSecne == 'factory' ? 'show' : 'hide' ]},[_c('div',{staticClass:"title",on:{"click":function($event){item.visible = !item.visible}}},[_c('div',{staticClass:"text"},[_vm._v(_vm._s(item.params.lineName))])])])}),_vm._l((_vm.lineList),function(item,index){return _c('div',{key:item.target + '_' + index,ref:("css2DLine" + index),refInFor:true,class:[
      'label',
      item.visible && _vm.$store.state.model.currentSecne == 'factory'
        ? 'show'
        : 'hide',
      'line' ],style:(item.style)},[_c('div',{staticClass:"title",style:({ cursor: item.target ? 'pointer' : 'auto' }),on:{"mouseover":function($event){_vm.curItem = index},"mouseleave":_vm.handleLeave}},[_c('div',{staticClass:"box",attrs:{"animateType":"toggle","isShow":_vm.curItem == index && item.target != null,"delay":0.1}},[_c('div',{staticClass:"body",style:({
            background:'#0b34bb9d',
          })},[_c('div',[_vm._v(_vm._s(item.params.lineName))]),_c('div',[_vm._v(" "+_vm._s(item.params.lineLength + "米")+" "+_vm._s(item.params.lineStatus == "ERROR" ? "异常" : item.params.lineStatus == "WARN" ? "警告" : "运行正常")+" ")]),_c('div',[_vm._v("xxx："+_vm._s(Math.floor(item.params.lineTime / 60))+" h")]),_c('div',[_vm._v("xxx："+_vm._s(Math.floor(item.params.lineMileage))+" km")]),_c('i',{staticClass:"el-icon-close btn-close",on:{"click":function($event){item.visible = !item.visible}}})])])])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }