<!--
 * @Author: 覃伟锋 792575027@qq.com
 * @Date: 2023-11-06 10:07:51
 * @LastEditors: 覃伟锋 792575027@qq.com
 * @LastEditTime: 2023-11-09 21:29:20
 * @FilePath: \DigitalTwin-main\src\views\model\symbol\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<script>
import { loadModel, onProgress, playAnimationByName } from "@/utils/action";
import loadingVue from "../../components/loading.vue";
export default {
  name: "Msymbol",
  inject: ["global"],
  props: {
    // home里的 模型列表
    modelList: {
      type: Array,
      default: () => [
        //   {
        //        name,
        //        url,
        //        draco,
        //        onprogress,
        //   }
      ],
    },
  },
  data() {
    return {};
  },
  render() {
    return null;
  },
  mounted() {
    if (this.modelList.length) {
      this.initLoad();
    }
  },
  watch: {
    modelList(data) {
      if (data.length) {
        this.initLoad();
      }
    },
  },
  methods: {
    // 初始加载 模型
    initLoad() {
      this.modelList.map((item) => {
        loadModel({
          data: item,
          draco: item.draco, // true/false
          url: item.url,
          complete: (object) => {
            let group = object.scene;
            group.name = item.name;
            if (object.animations && object.animations.length) {
              // playAnimationByName(object, this.global); // 播放动画
            }
            let scale = 0.0003 * 1;
            group.scale.set(scale, scale, scale);
            // group.rotateX(Math.PI / 2);
            // group.rotateY(-Math.PI / 2);
            group.position.set(0, 0, 0);
            this.global.scene.add(group);
            item.callback && item.callback(group);
          },
          onprocess: (xhr) => {
            if (item.onprogress) {
              onProgress(xhr, () => {
                this.$emit("progress", (xhr.loaded / xhr.total) * 100);
              });
              return true;
            } else {
              return false;
            }
          },
        });
      });
    },
  },
};
</script>

<style></style>
